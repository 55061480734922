import { IGroup } from 'types/IGroup';
import Input from 'components/FormControls/Input';
import {
  Modal, Row, Col,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { saveNewGroup } from 'reducers/Groups';
import { toast } from 'react-toastify';
import { RootState } from 'types/rootState';
import React, {
  Dispatch, SetStateAction, ReactElement,
} from 'react';
import SubmitButton from 'components/FormControls/SubmitButton'
import { segmentAnalyticsTrack, trackActions } from 'lib/SegmentTool';

interface IProps {
  showAddGroupModal: boolean
  setShowAddGroupModal: Dispatch<SetStateAction<boolean>>
  setEditMode: Dispatch<SetStateAction<boolean>>
}

function AddGroupActionButton(
  { showAddGroupModal, setShowAddGroupModal, setEditMode }: IProps,
): ReactElement {
  const {
    control, errors, handleSubmit,
  } = useForm<IGroup>({ mode: 'onBlur' });
  const dispatch = useDispatch();
  const saving = useSelector((state: RootState) => state.Groups.loading);

  const interceptSubmit = async (groupFormData: IGroup): Promise<void> => {
    // await here so the selected group id gets set before edit mode renders
    segmentAnalyticsTrack(trackActions.createGroup());
    await dispatch(saveNewGroup(groupFormData, () => {
      toast.success('Group successfully created');
    }));
    setShowAddGroupModal(false);
    setEditMode(true);
  };

  return (
    <Modal
      show={showAddGroupModal}
      onHide={() => setShowAddGroupModal(false)}
      className="DeleteActionButtonModal"
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header>
        <h4>Create New Group</h4>
      </Modal.Header>
      <Modal.Body>
        <hr />
        <form onSubmit={handleSubmit(interceptSubmit)} className="form ContentForm">
          <Row>
            <Col xs={1}>
              <span>Name</span>
            </Col>
          </Row>
          <Row>
            <Col xs={8}>
              <Input
                id="Name"
                name="Name"
                type="text"
                control={control}
                errors={errors.Name && errors.Name.message}
                rules={{
                  required: 'Name is required',
                  validate: (val: any) => val.length <= 50 || 'Group name must not exceed 50 characters',
                }}
              />
            </Col>
          </Row>
          <Row>
            <SubmitButton
              label="Create"
              savingLabel="Creating..."
              className="btn btn-block btn-primary"
              saveComplete={!saving}
            />
            <button
              type="button"
              className="btn btn-block btn-secondary"
              onClick={() => { setShowAddGroupModal(false); setEditMode(false); }}
            >
              Cancel
            </button>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

export default AddGroupActionButton;
