import React from 'react';
import clsx from 'clsx';
import InputMask from 'react-input-mask';

import { ContentEditable, ContentEditableInputProps } from './ContentEditable';

interface InputSharedProps {
  type: string
  label?: string
  mask?: string
  errors?: string
  icon?: React.FC<React.SVGAttributes<SVGElement>>
}

type InputContentEditableProps = ContentEditableInputProps & Omit<InputSharedProps, 'type'|'mask'>;

type InputInputProps = React.InputHTMLAttributes<HTMLInputElement> & InputSharedProps & {
  value?: string|null
  onChange?: (...event: any[]) => void
}

export type InputProps = InputInputProps & InputContentEditableProps;

const Input = React.forwardRef<HTMLInputElement|HTMLDivElement, InputProps>(
  (
    {
      id,
      name,
      label,
      value = '',
      mask = '',
      required,
      errors,
      icon: Icon,
      highlights,
      className = '',
      style = {},
      disabled,
      placeholder,
      readOnly,
      onChange,
      onKeyDown,
      maxLength,
      ...props
    },
    ref,
  ) => {
    const inputProps = {
      id,
      name,
      value,
      required,
      disabled,
      readOnly,
      className: clsx('form-control', errors && 'invalid'),
      onChange,
      maxLength,
      onKeyDown,
      placeholder,
      ...props,
    };

    const renderInput = () => {
      if (mask.length) {
        return (
          <InputMask
            {...inputProps}
            mask={mask}
            inputRef={(node) => {
              if (typeof ref === 'object') {
                // eslint-disable-next-line no-param-reassign
                ref.current = node;
                return;
              }
              ref(node);
            }}
            alwaysShowMask
          />
        )
      }

      if (highlights) {
        return (
          <ContentEditable
            {...inputProps}
            highlights={highlights}
            oneLine
            ref={ref as React.Ref<HTMLDivElement>}
          />
        )
      }

      return <input {...inputProps} ref={ref as React.Ref<HTMLInputElement>} />
    };

    return (
      <div
        className={clsx('Input', 'form-group', className)}
        style={style}
      >
        {label && (
          <label htmlFor={id}>
            {label}
          </label>
        )}

        <div className="position-relative">
          {!!maxLength && (
            <span className="maxlength-info">
              {`${value?.length ?? 0}/${maxLength}`}
            </span>
          )}
          {required ? <span className="required-asterisk">*</span> : null}
          {renderInput()}
          {Icon && <Icon className="Input__icon" />}
        </div>
        {errors ? <span className="errors">{errors}</span> : ''}
      </div>
    );
  },
);

export default Input;
