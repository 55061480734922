import React, { useEffect, useRef } from 'react';
import * as pbi from 'powerbi-client';
import { PbiEmbedReportResponse } from 'api/PowerBiApi';

const PbiReportEmbed: React.FC<PbiEmbedReportResponse> = ({ EmbedToken, EmbedUrl }) => {
  const embedContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (embedContainer.current && EmbedToken && EmbedUrl) {
      const powerbi = new pbi.service.Service(
        pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      const embedConfig: pbi.IEmbedConfiguration = {
        type: 'report',
        tokenType: pbi.models.TokenType.Embed,
        accessToken: EmbedToken,
        embedUrl: EmbedUrl,
        settings: {
          panes: {
            filters: {
              visible: false,
            },
            pageNavigation: {
              visible: false,
            },
          },
        },
      };

      powerbi.embed(embedContainer.current, embedConfig);
    }
  }, [EmbedUrl, EmbedToken]);

  return (
    <div ref={embedContainer} style={{ height: '100vh', width: '100%' }} />
  );
};

export default PbiReportEmbed;
