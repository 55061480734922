import React, { FC, useState, useEffect } from 'react';
import {
  useForm, SubmitHandler, FormProvider, Controller,
} from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';
import {
  useDispatch, useSelector,
} from 'react-redux';
import { toast } from 'react-toastify';

import {
  selectDischargedPatientConfig,
  updateDischargedPatientConfig,
  getDischargedPatientConfig,
} from 'reducers/DischargedPatients';
import { Input, Textarea } from 'components/FormControls';
import { IFormInputs } from 'pages/Administration/DischargedPatient/types';
import Checkbox from 'components/Inputs/Checkbox';
import Forbidden from '../forbidden/Forbidden';
import Transitions from '../transitions/Transitions';

export const StatesForm: FC<{ readMode: boolean, onCancel: () => void }> = ({ readMode, onCancel }) => {
  const formMethods = useForm<IFormInputs>({
    mode: 'onBlur',
  });
  const {
    handleSubmit, control, errors, setValue, getValues, reset,
  } = formMethods;
  const config = useSelector(selectDischargedPatientConfig);
  const dispatch = useDispatch();

  const [allStates, setAllStates] = useState([]);

  useEffect(() => {
    if (!config) {
      return;
    }

    setAllStates(config.ConfigurationData?.StatusConfiguration?.States ?? []);

    const transitions = Object.entries(config?.ConfigurationData?.StatusConfiguration?.Transitions)
      .reduce((acc, [key, value]) => {
        const [TransitionFrom, TransitionTo] = key.split(':');
        return [
          ...acc,
          {
            TransitionFrom,
            TransitionTo,
            Action: value,
          },
        ]
      }, []);

    const forbidden = config?.ConfigurationData?.StatusConfiguration?.Forbidden
      .map((transition) => {
        const [TransitionFrom, TransitionTo] = transition.split(':');
        return {
          TransitionFrom,
          TransitionTo,
        }
      });

    const defaultValues = {
      States: config.ConfigurationData?.StatusConfiguration?.States?.join() ?? '',
      Transitions: transitions,
      Forbidden: forbidden,
      ScheduleStrategy: config.ConfigurationData?.ScheduleStrategy ?? '',
      EndState: config.ConfigurationData?.StatusConfiguration?.EndState ?? '',
      StartState: config.ConfigurationData?.StatusConfiguration?.StartState ?? '',
      MovePatientToEndStateOnLastCall: config.ConfigurationData?.StatusConfiguration?.MovePatientToEndStateOnLastCall,
    }

    reset(defaultValues);
  }, [config, reset, readMode]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const transitions = data.Transitions
      .filter((item) => item.TransitionFrom !== '')
      .reduce((acc, { TransitionFrom, TransitionTo, Action }) => ({
        ...acc,
        [`${TransitionFrom}:${TransitionTo}`]: Action,
      }), {});

    const forbidden = data.Transitions
      .filter((item) => item.TransitionFrom !== '')
      .reduce((acc, { TransitionFrom, TransitionTo }) => [
        ...acc,
        `${TransitionFrom}:${TransitionTo}`,
      ], []);

    const objectToEndpoint = {
      inboundnumber: config.InboundNumber,
      Configuration: {
        EmailNotificationData: config.ConfigurationData?.EmailNotificationData,
        ForwardNumber: config.ConfigurationData?.ForwardNumber,
        DefaultSurveyQuestions: config.ConfigurationData?.DefaultSurveyQuestions,
        FacilityInfoToCallerIdMapping: config.ConfigurationData?.FacilityInfoToCallerIdMapping,
        Tags: config.ConfigurationData?.Tags,
        SurveyCallData: config.ConfigurationData?.SurveyCallData,
        SurveyCallName: config.ConfigurationData?.SurveyCallName,
        ScheduleStrategy: data.ScheduleStrategy,
        StatusConfiguration: {
          States: allStates,
          Transitions: transitions,
          Forbidden: forbidden,
          StartState: data.StartState,
          EndState: data.EndState,
          MovePatientToEndStateOnLastCall: data.MovePatientToEndStateOnLastCall,
        },
      },
    }

    dispatch(updateDischargedPatientConfig(objectToEndpoint, () => {
      toast.success('Discharged Patient Configuration successfully saved');
      getDischargedPatientConfig()
      onCancel();
    }));
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-100 mt-3">
        <Row>
          <Col xs={12} md={6}>
            <Input
              id="ScheduleStrategy"
              name="ScheduleStrategy"
              label="Schedule Strategy"
              type="text"
              control={control}
              rules={{ required: 'Schedule Strategy is required' }}
              errors={errors.ScheduleStrategy?.message}
              readOnly={readMode}
            />
            <Textarea
              id="States"
              control={control}
              name="States"
              label="States"
              errors={errors.States?.message}
              rules={{
                required: 'States is required',
              }}
              onChange={(e) => {
                const { name, value } = e.target;
                setValue(name, value);
                const states = value.split(/\s*,\s*/).filter(Boolean);
                setAllStates(states);
                setValue('EndState', '');
                setValue('StartState', '');
                const values = getValues();
                values.Transitions.forEach((item, index) => {
                  setValue(`Transitions.${index}.TransitionFrom`, '');
                  setValue(`Transitions.${index}.TransitionTo`, '');
                  setValue(`Transitions.${index}.Action`, '');
                });
                values.Forbidden.forEach((item, index) => {
                  setValue(`Forbidden.${index}.TransitionFrom`, '');
                  setValue(`Forbidden.${index}.TransitionTo`, '');
                });
              }}
              readOnly={readMode}
            />
            <Input
              id="StartState"
              name="StartState"
              label="Start State"
              type="text"
              control={control}
              rules={{
                required: 'Start State is required',
                validate: (val: string) => {
                  if (!allStates.includes(val.trim())) {
                    return 'Start State should be a value from States'
                  }

                  return true;
                },
              }}
              errors={errors.StartState?.message}
              readOnly={readMode}
            />
            <Input
              id="EndState"
              name="EndState"
              label="End State"
              type="text"
              control={control}
              rules={{
                required: 'End State is required',
                validate: (val: string) => {
                  if (!allStates.includes(val.trim())) {
                    return 'End State should be a value from States'
                  }

                  return true;
                },
              }}
              errors={errors.EndState?.message}
              readOnly={readMode}
            />
          </Col>
          <Col xs={12} md={6}>
            <Transitions states={allStates} readMode={readMode} />
            <Forbidden states={allStates} readMode={readMode} />
            <Controller
              control={control}
              name="MovePatientToEndStateOnLastCall"
              render={({ onBlur, value }) => (
                <Checkbox
                  name="MovePatientToEndStateOnLastCall"
                  label="Move To End State On Last Call"
                  checked={value}
                  onBlur={onBlur}
                  onChange={(e) => {
                    const { name, checked } = e.target;
                    setValue(name, checked);
                  }}
                  disabled={readMode}
                />
              )}
            />
          </Col>
        </Row>
        {!readMode && (
          <div className="w-100 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link mr-3"
              onClick={onCancel}
            >
              Cancel
            </button>

            <Button
              type="submit"
            >
              Save
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  )
}
