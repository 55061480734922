import React, {
  FC, ReactElement, useCallback, useMemo, useState,
} from 'react';
import {
  useQueryParam, NumberParam, StringParam,
  BooleanParam,
} from 'use-query-params';
import { BulkActions } from 'components/BulkActions';
import Pagination from 'components/Pagination';
import Table from 'components/Table';
import PageSizePicker from 'components/PageSizePicker';
// import moment from 'moment';
// import { useSelector } from 'react-redux';
// import { selectAccountTz } from 'reducers/UserInfo';
import IndexFilter, { ISearchQuery } from 'components/Inputs/IndexFilter_Id_Name';
import { Tab, Tabs } from 'react-bootstrap';
import { DeleteActionModal } from 'components/DeleteActionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as BroadcastFormApi from 'api/BroadcastFormAPI';
import useSWRMutation from 'swr/mutation';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useEbfGeneratorsQuery } from 'components/hooks/useEbfGeneratorsQuery';

type EbfGeneratorTableProps = {
  showActions?: boolean
  filterTitle?:string
};

// const formatDateSentCell = (acctTz: string) => (props: CellProps<Record<string, unknown>>) => {
//   const { cell: { value } } = props;

//   const localTime = moment
//     .utc(value, 'YYYY-MM-DDTHH:mm:ss.SSS')
//     .tz(acctTz);

//   return localTime.format('MMM D, YYYY h:mm a');
// }

export const EbfGeneratorTable: FC<EbfGeneratorTableProps> = ({
  showActions,
  filterTitle,
}) => {
  const { data, isLoading, mutate } = useEbfGeneratorsQuery();
  // const accountTz = useSelector(selectAccountTz);
  const [pageParam = 1, setPageParam] = useQueryParam('page', NumberParam);
  const [perPageParam = 10, setPerPageParam] = useQueryParam('perpage', NumberParam);
  const [sortFieldParam, setSortFieldParam] = useQueryParam('sortField', StringParam);
  const [sortDirectionParam, setSortDirectionParam] = useQueryParam('sortDirection', StringParam);
  const [isActiveParam, setIsActiveParam] = useQueryParam('isActive', BooleanParam);
  const isActive = isActiveParam ?? true;
  const [formToDeleteID, setFormToDeleteID] = useState<number|undefined>();
  const [formToCompleteID, setFormToCompleteID] = useState<number|undefined>();

  const history = useHistory();

  const [searchQuery, setSearchQuery] = useQueryParam(
    'searchQuery',
    StringParam,
  );

  const getSearchQuery = useCallback((): ISearchQuery => {
    const query = searchQuery?.split(',');
    if (!query) return {};
    return { searchField: query[0], searchValue: query[1] };
  }, [searchQuery, isActive]);

  const onSearch = (searchingQuery: ISearchQuery): void => {
    if (searchingQuery.searchField || searchingQuery.searchValue) {
      setSearchQuery(
        `${searchingQuery.searchField || ''},${searchingQuery?.searchValue || ''}`,
      );
    } else {
      setSearchQuery('');
    }
    setPageParam(1, 'pushIn');
  };

  const resetFilters = (): void => {
    setSearchQuery('', 'pushIn');
    setPageParam(1, 'pushIn');
  };

  const columns = useMemo(() => [
    {
      Header: 'GeneratorId', accessor: 'GeneratorId', sortable: true, showToolTip: true,
    },
    {
      Header: 'Survey Name', accessor: 'SurveyName', sortable: true, showToolTip: true,
    },
    {
      Header: 'Content', accessor: 'FormType', sortable: true, showToolTip: true,
    },
    {
      Header: 'Description', accessor: 'Description', sortable: true, showToolTip: true,
    },
  ].filter(Boolean), []);

  const items = data?.Data ?? [];
  const updateBroadcastFormStatus = async (url, { arg }: {arg: { formId: number, isComplete: boolean }}) => {
    await BroadcastFormApi.completeBroadcastForm(arg.formId, arg.isComplete);
  }
  const { trigger: updateStatus } = useSWRMutation('updateStatus', updateBroadcastFormStatus, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      setFormToCompleteID(undefined);
      toast.success('Broadcast form successfully completed');
      mutate();
    },
  });

  const deleteBroadcastForm = async (url, { arg }: {arg: { formId: number }}) => {
    await BroadcastFormApi.broadcastFormDelete(arg.formId);
  }

  const { trigger: deleteForm } = useSWRMutation('deletebroadcastform', deleteBroadcastForm, {
    revalidate: true,
    populateCache: false,
    onSuccess: () => {
      setFormToDeleteID(undefined);
      toast.success('Broadcast form successfully deleted');
      mutate();
    },
  });

  const handleDelete = () => {
    deleteForm({ formId: formToDeleteID });
  }

  const handleMarkComplete = () => {
    updateStatus({ formId: formToCompleteID, isComplete: true });
  }

  const renderIndexFilter = (): ReactElement => (
    <IndexFilter
      searchQuery={getSearchQuery()}
      onSearch={onSearch}
      onResetFilters={resetFilters}
      title={filterTitle}
      totalItems={data?.Pagination?.TotalItems ?? 0}
      itemsLoading={isLoading}
      defaultField="name"
    />
  );

  const rowBulkActionItems = (entityId: number): ReactElement => {
    const actionItems = [];
    actionItems.push({
      label: 'Edit',
      handler: () => {
        history.push(`/internal/ebfgenerators/${entityId}`);
      },
    });
    actionItems.push({
      label: 'Schedule',
      handler: () => {
        history.push(`/internal/ebfgenerators/${entityId}/events`);
      },
    });
    // if (isActiveParam) {
    //   actionItems.push({
    //     label: 'Resend',
    //     handler: () => {
    //       history.push(`/urllinksurvey/${entityId}/resent`);
    //     },
    //   });
    //   // actionItems.push({
    //   //   label: 'Mark Completed',
    //   //   handler: () => {
    //   //     // setFormToCompleteID(entityId);
    //   //   },
    //   // });
    //   // actionItems.push({
    //   //   label: 'Delete',
    //   //   handler: () => {
    //   //     // setFormToDeleteID(entityId);
    //   //   },
    //   //   className: 'delete-action',
    //   // });
    // }
    return (
      <BulkActions
        className="ml-3"
        items={actionItems}
      />
    );
  };

  return (
    <div>
      {renderIndexFilter()}
      <Tabs
        defaultActiveKey={isActive ? 'active' : 'inactive'}
        onSelect={(val) => {
          setPageParam(1)
          setIsActiveParam(val === 'active')
        }}
      >
        <Tab eventKey="active" title="Active Event Generators">
          <Table
            columns={columns}
            enableCheck={false}
            data={items}
            selectButton={undefined}
            rowIDAccessor="GeneratorId"
            isLoading={isLoading}
            rowActionItems={showActions ? rowBulkActionItems : undefined}
            onColumnSort={(fieldName, direction) => {
              if (fieldName.length > 0) {
                setSortFieldParam(fieldName);
                setSortDirectionParam(direction);
              } else {
                setSortFieldParam('');
                setSortDirectionParam('');
              }
            }}
            sortedColumn={sortFieldParam}
            sortedColumnDirection={sortDirectionParam}
            className="Template-Table"
          />
        </Tab>
        <Tab eventKey="inactive" title="Inactive Event Generators">
          <Table
            columns={columns}
            enableCheck={false}
            data={items}
            selectButton={undefined}
            rowIDAccessor="GeneratorId"
            isLoading={isLoading}
            rowActionItems={showActions ? rowBulkActionItems : undefined}
            onColumnSort={(fieldName, direction) => {
              if (fieldName.length > 0) {
                setSortFieldParam(fieldName);
                setSortDirectionParam(direction);
              } else {
                setSortFieldParam('');
                setSortDirectionParam('');
              }
            }}
            sortedColumn={sortFieldParam}
            sortedColumnDirection={sortDirectionParam}
            className="Template-Table"
          />
        </Tab>
      </Tabs>
      <div className="paging">
        <PageSizePicker
          initialPageSize={perPageParam}
          onSetPageSize={(size) => {
            setPageParam(1, 'replaceIn');
            setPerPageParam(size);
          }}
        />
        <Pagination
          currentPage={pageParam}
          totalPages={data?.Pagination?.TotalPages ?? 1}
          onPageChange={(newPage: number) => setPageParam(newPage, 'pushIn')}
          pageDelta={5}
        />
      </div>
      <DeleteActionModal
        isOpen={formToDeleteID !== undefined}
        title="Are you sure?"
        onCancel={() => setFormToDeleteID(undefined)}
        onSuccess={() => {
          handleDelete();
          setFormToDeleteID(undefined);
        }}
      />
      <DeleteActionModal
        isOpen={formToCompleteID !== undefined}
        title="Are you sure?"
        onCancel={() => setFormToCompleteID(undefined)}
        onSuccess={() => {
          handleMarkComplete();
          setFormToCompleteID(undefined);
        }}
        buttonName="Mark Completed"
        deleteButtonClassName="btn-primary"
        customIcon={(
          <FontAwesomeIcon
            icon="exclamation-circle"
            size="4x"
            color="#FFAE42"
          />
        )}
      >
        <div>
          Once a survey has been marked completed no more responses can be submitted. This cannot be undone
        </div>
      </DeleteActionModal>
    </div>
  );
};
