import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import { Spinner } from 'react-bootstrap';
import * as PbiApi from 'api/PowerBiApi';
import PbiReportEmbed from 'components/PowerBi/PbiReportEmbed';

const fetchReportData = async (datasetId: string, reportId: string, pbiSessionId: string) => {
  const response = await PbiApi.getEmbeddedReport(datasetId, reportId, pbiSessionId, {
    ignoreGlobalCatch: true,
  });
  return response;
};

const PbiEmbeddedReport = (): ReactElement => {
  const { datasetId, reportId, pbiSessionId } = useParams<{
    datasetId: string
    reportId: string
    pbiSessionId: string
  }>();

  const { data: reportEmbedInfo, error } = useSWR(
    ['embeddedReport', datasetId, reportId, pbiSessionId],
    () => fetchReportData(datasetId, reportId, pbiSessionId),
    {
      shouldRetryOnError: false,
    },
  );

  if (!pbiSessionId) {
    return <h2>Invalid URL, pbi session id must be provided</h2>;
  }

  if (!reportEmbedInfo && !error) {
    return (
      <div className="loading-text d-flex align-items-center">
        <Spinner animation="border" variant="primary" role="status" className="mr-3" />
        Loading...
      </div>
    );
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  if (!reportEmbedInfo) {
    return <h2>Unable to get report data.</h2>;
  }

  const { EmbedToken, EmbedUrl } = reportEmbedInfo;

  return (
    <div>
      <PbiReportEmbed EmbedToken={EmbedToken} EmbedUrl={EmbedUrl} />
    </div>
  );
};

export default PbiEmbeddedReport;
