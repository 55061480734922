import React, { useState } from 'react';
import SideBar from './SideBar';
import MobileNav from './MobileNav';

function AppWrapper({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

  return (
    <div className="AppWrapper">
      <SideBar open={sidebarOpen} openMenuHandler={setSidebarOpen} />
      <div className="Main">
        <div className="content">{children}</div>
      </div>
      <MobileNav sidebarOpen={sidebarOpen} openMenuHandler={setSidebarOpen} />
    </div>
  )
}

export default AppWrapper;
