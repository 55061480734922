import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import JwtDecode from 'jwt-decode';
import log from 'lib/logging';
import PersistNavItem from 'lib/PersistNavItem';
import moment from 'moment';
import api from 'api/API';
import qs from 'query-string';
import { toast } from 'react-toastify';
import PersistAuth from '../lib/PersistAuth';
import { RootState } from '../types/rootState';
import { AppThunk } from '../app/appThunk';
import {
  IAuthResult,
  ITokenContents,
  IUserAccount,
  IUserInfo,
  IUserInfoState,
  IPermissions,
  IOAuthResult,
} from './IUserInfoState';
import { hasError } from './Error';

export const INITIAL_STATE: IUserInfoState = {
  email: '',
  FirstName: '',
  LastName: '',
  nameid: '',
  roles: [] as string[],
  permissions: {},
  uniqueName: '',
  token: '',
  accounts: [],
  accountsLoading: false,
  userId: 0,
  changePassword: false,
  jwt: {
    nbf: 0,
    exp: 0,
    iat: 0,
    iss: '',
    aud: '',
  },
  error: {
    failure: false,
    message: '',
  },
  passwordError: {
    failure: false,
    message: '',
  },
  resetKeyError: {
    failure: false,
    message: '',
  },
  loading: false,
  loggedIn: false,
  accountIntegrations: [],
  startPath: '/calendar',
  activeAccountName: '',
  accountTimezone: 'America/New_York',
  requireMfa: false,
  loginUserId: 0,
  linkVoicefriendError: {
    failure: false,
    message: '',
  },
};

const UserInfoSlice = createSlice({
  name: 'UserInfo',
  initialState: getInitialState(),
  reducers: {
    refreshTokenSuccess: (draftReducerState, action) => {
      try {
        const { newToken } = action.payload;
        const newState = decodeTokenToState(newToken);
        Object.assign(draftReducerState, newState);
        handleSaveToken(newToken);
      } catch (err) {
        log.error('JWT REFRESH', err);
      }
    },
    authenticateUserStarted(draftReducerState) {
      draftReducerState.loading = true;
    },
    authenticateUserSuccess(draftReducerState, action: PayloadAction<IAuthResult>) {
      try {
        const token = action.payload.Token;
        const newState = decodeTokenToState(token);
        Object.assign(draftReducerState, newState);
        handleSaveToken(token);
      } catch (err) {
        log.error('JWT DECODE', err);
        draftReducerState.loading = false;
        draftReducerState.loggedIn = false;
        draftReducerState.error = {
          failure: true,
          message: 'Something went wrong',
        };
      }
    },
    oauthUserSuccess(draftReducerState, action: PayloadAction<IOAuthResult>) {
      try {
        const token = action.payload.Access_Token;
        const refreshToken = action.payload.Refresh_Token;
        PersistAuth.storeRefreshToken(refreshToken);
        const newState = decodeTokenToState(token);
        Object.assign(draftReducerState, newState);
        handleSaveToken(token);
      } catch (err) {
        log.error('JWT DECODE', err);
        draftReducerState.loading = false;
        draftReducerState.loggedIn = false;
        draftReducerState.error = {
          failure: true,
          message: 'Something went wrong',
        };
      }
    },
    oauthUserFailed(draftReducerState, err: any) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
      draftReducerState.error = {
        failure: true,
        message: err.payload.response.data.error_description,
      };
    },
    authenticateUserFailed(draftReducerState, err: any) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
      draftReducerState.error = {
        failure: true,
        message: err.payload.response.data,
      };
    },
    authenticateUserFailedNoError(draftReducerState) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
    },
    getUserAccountsStarted(draftReducerState) {
      draftReducerState.accountsLoading = true;
    },
    getUserAccountsSuccess(draftReducerState, action: PayloadAction<IUserAccount[]>) {
      draftReducerState.accounts = action.payload;
      draftReducerState.accountsLoading = false;
    },
    getUserAccountsFailed(draftReducerState) {
      draftReducerState.accountsLoading = false;
      log.error('error fetching user accounts');
    },
    getUserInfoSuccess(draftReducerState, action: PayloadAction<IUserInfo>) {
      draftReducerState.userInfo = action.payload;
    },
    activationKeyValidateSuccess(draftReducerState) {
      draftReducerState.loading = false;
    },
    activationKeyValidateFailed(draftReducerState, err: any) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
      draftReducerState.error = {
        failure: true,
        message: err.payload.response.data.Message,
      };
    },
    activateFailed(draftReducerState, err: any) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
      draftReducerState.error = {
        failure: false, // TODO: we may need another way here
        message: err.payload.response.data.Message,
      };
    },
    passwordValidateFailed(draftReducerState, err: PayloadAction<any>) {
      draftReducerState.loading = false;
      draftReducerState.passwordError = {
        failure: true,
        message: err.payload,
      };
    },
    passwordValidateSuccess(draftReducerState) {
      draftReducerState.loading = false;
      draftReducerState.passwordError = {
        failure: false,
        message: '',
      };
    },
    forgotPasswordSuccess(draftReducerState) {
      draftReducerState.loading = false;
      draftReducerState.error = {
        failure: false,
        message: '',
      };
    },
    resetKeyValidateFailed(draftReducerState, err: any) {
      draftReducerState.loading = false;
      draftReducerState.loggedIn = false;
      draftReducerState.resetKeyError = {
        failure: true,
        message: err?.payload?.response?.data,
      };
    },
    getAccountSettingSuccess(draftReducerState, action: PayloadAction<string>) {
      draftReducerState.userInfo.ProfileColumnsSetting = action.payload;
    },
    linkVoicefriendFailed(draftReducerState, err: PayloadAction<any>) {
      draftReducerState.loading = false;
      draftReducerState.error = {
        failure: true,
        message: err.payload,
      };
    },
    linkVoicefriendSuccess(draftReducerState) {
      draftReducerState.loading = false;
      draftReducerState.error = {
        failure: false,
        message: '',
      };
    },
    fetchCaremergeJwtSuccess(draftReducerState) {
      draftReducerState.loading = false;
      draftReducerState.error = {
        failure: false,
        message: '',
      };
    },
    fetchCaremergeJwtFailed(draftReducerState, err: PayloadAction<any>) {
      draftReducerState.loading = false;
      draftReducerState.error = {
        failure: true,
        message: err.payload,
      };
    },
    updateAccountCommunityNameSuccess(draftReducerState, action: PayloadAction<string>) {
      draftReducerState.userInfo.AccountDetail.CommunityName = action.payload;
    },
  },
});

function getInitialState(): IUserInfoState {
  const token = PersistAuth.getToken();
  if (token.length > 0) {
    api.setAuthHeader(token);

    try {
      const state = decodeTokenToState(token);
      return state;
    } catch {
      handleDestroyToken();
      return INITIAL_STATE;
    }
  } else {
    return INITIAL_STATE;
  }
}

const DEFAULT_PERMISSION = {
  Create: false, Update: false, Read: false, Delete: false,
};

export function decodePermissionsData(rawPermissions: Array<string> | string): IPermissions {
  const permissions = {};
  if (!rawPermissions) {
    return permissions;
  }
  if (typeof rawPermissions === 'string') {
    const [permission, mode] = rawPermissions.split(':');
    permissions[permission] = { ...DEFAULT_PERMISSION }; // clone the default object.  TODO should we use immer here?
    permissions[permission][mode] = true;
    return permissions;
  }

  for (const p of rawPermissions) {
    const [permission, mode] = p.split(':');
    if (!(permission in permissions)) {
      permissions[permission] = { ...DEFAULT_PERMISSION }; // clone default object
    }
    permissions[permission][mode] = true;
  }
  return permissions;
}

export function decodeTokenToState(token: string): IUserInfoState {
  const jwt = JwtDecode<ITokenContents>(token);
  const {
    email,
    FirstName,
    LastName,
    nameid,
    unique_name: uniqueName,
    nbf,
    exp,
    iat,
    iss,
    aud,
    VF_UserID: userId,
    RequiredAction,
    StartPath,
    ActiveAccountName,
    AccountTimezone,
    LoginUserId,
  } = jwt;

  const permissions = decodePermissionsData(jwt.permissions);

  // make sure roles always string array even if user has only 1 role in returned jwt.
  let roles = new Array<string>();
  if (typeof jwt.role === 'string') {
    roles.push(jwt.role);
  } else {
    roles = roles.concat(jwt.role);
  }

  return {
    email,
    FirstName,
    LastName,
    nameid,
    roles,
    permissions,
    uniqueName,
    userId,
    token,
    accountsLoading: false,
    jwt: {
      nbf,
      exp,
      iat,
      iss,
      aud,
    },
    error: {
      failure: false,
      message: '',
    },
    passwordError: {
      failure: false,
      message: '',
    },
    resetKeyError: {
      failure: false,
      message: '',
    },
    loading: false,
    loggedIn: true,
    changePassword: Array.isArray(RequiredAction)
      ? RequiredAction.some((x) => x === 'ChangePassword') : RequiredAction === 'ChangePassword',
    requireMfa: Array.isArray(RequiredAction)
      ? RequiredAction.some((x) => x === 'Mfa') : RequiredAction === 'Mfa',
    startPath: StartPath,
    accountIntegrations: [],
    activeAccountName: ActiveAccountName,
    accountTimezone: AccountTimezone,
    loginUserId: LoginUserId,
    linkVoicefriendError: {
      failure: false,
      message: '',
    },
  };
}

export const {
  authenticateUserStarted,
  authenticateUserSuccess,
  authenticateUserFailed,
  authenticateUserFailedNoError,
  oauthUserSuccess,
  oauthUserFailed,
  getUserAccountsStarted,
  getUserAccountsSuccess,
  getUserAccountsFailed,
  getUserInfoSuccess,
  activationKeyValidateSuccess,
  activationKeyValidateFailed,
  activateFailed,
  passwordValidateFailed,
  passwordValidateSuccess,
  resetKeyValidateFailed,
  refreshTokenSuccess,
  forgotPasswordSuccess,
  getAccountSettingSuccess,
  linkVoicefriendFailed,
  linkVoicefriendSuccess,
  fetchCaremergeJwtSuccess,
  fetchCaremergeJwtFailed,
  updateAccountCommunityNameSuccess,
} = UserInfoSlice.actions;

export default UserInfoSlice.reducer;

export const authenticate = (
  UserName: string,
  Password: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const authResponse = await Api.UserInfo.authenticateUser(UserName, Password);
    handleSaveToken(authResponse.Access_Token);
    log.info('before success');
    dispatch(oauthUserSuccess(authResponse));
    log.info('after success');
  } catch (err) {
    dispatch(oauthUserFailed(err));
  }
};

export const logUserWithResetKey = (
  ResetKey: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const authResponse = await Api.UserInfo.authenticateUserWithResetKey(ResetKey);
    if (authResponse.Success) {
      handleSaveToken(authResponse.Token);
      log.info('before success');
      dispatch(authenticateUserSuccess(authResponse));
      log.info('after success');
    }
  } catch (err) {
    if (err?.response?.status === 400) {
      dispatch(resetKeyValidateFailed(err));
    } else {
      dispatch(authenticateUserFailed(err));
    }
  }
};

export const forgotPassword = (
  UserName: string,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    await Api.UserInfo.sendForgotPassword(UserName);
    dispatch(forgotPasswordSuccess());
  } catch (err) {
    dispatch(authenticateUserFailed(err));
  }
};

export const changeUserPassword = (
  Password: string,
  callback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const authResponse = await Api.UserInfo.changePassword(Password);
    handleSaveToken(authResponse.Token);
    log.info('before success');
    dispatch(authenticateUserSuccess(authResponse));
    log.info('after success');
    callback();
  } catch (err) {
    dispatch(authenticateUserFailed(err));
    dispatch(hasError(err));
  }
};

// export const fetchUserAccounts = (): AppThunk => async (dispatch, getState, Api) => {
//   dispatch(getUserAccountsStarted());
//   try {
//     const userAccountsResponse = await Api.UserInfo.getUserAccounts();
//     dispatch(getUserAccountsSuccess(userAccountsResponse));
//   } catch (err) {
//     dispatch(getUserAccountsFailed());
//     dispatch(hasError(err));
//   }
// };

export const changeCurrentAccount = (
  accountId: number,
  redirectCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  const { Token: token } = await Api.UserInfo.changeCurrentAccount(accountId);
  handleSaveToken(token);
  redirectCallback();
};

export const tryChangeCurrentAccount = (
  accountId: number,
  redirectCallback: () => void,
  failedCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  try {
    const { Token: token } = await Api.UserInfo.changeCurrentAccount(accountId);
    handleSaveToken(token);
    redirectCallback();
  } catch (err) {
    toast.error(err.response.data);
    failedCallback();
  }
};

export const exchangeToken = (callback: () => any): AppThunk => async (dispatch, getState, Api) => {
  const { Token } = await Api.UserInfo.exchangeToken();
  handleSaveToken(Token);
  dispatch(fetchUserInfo());
  dispatch(authenticateUserSuccess({ Token, Success: true }));
  callback();
};

export const fetchUserInfo = (): AppThunk => async (dispatch, getState, Api) => {
  try {
    const userInfoResponse = await Api.UserInfo.getUserInfo();
    dispatch(getUserInfoSuccess(userInfoResponse));
    dispatch(fetchAccountSetting('defaultprofiletablelayout'));
  } catch (err) {
    log.error(err);
    dispatch(hasError(err));
  }
};
export const fetchAccountSetting = (settingName: string): AppThunk => async (dispatch, getState, Api) => {
  try {
    const accountSettingResponse = await Api.UserInfo.getAccountSetting(settingName);
    dispatch(getAccountSettingSuccess(accountSettingResponse));
  } catch (err) {
    log.error(err);
    dispatch(hasError(err));
  }
};

// Selectors

export const selectUserRoles = (state: RootState): string[] => state.UserInfo.roles
export const selectUserPermissions = (state: RootState): IPermissions => (
  state.UserInfo.permissions
)

export const selectJwtExp = (state: RootState): number => state.UserInfo.jwt.exp;

export const selectComplianceDateFieldNames = (
  state: RootState,
): IUserInfo['ComplianceDates'] | undefined => state.UserInfo.userInfo?.ComplianceDates;

export const selectLifeDateFieldNames = (
  state: RootState,
): IUserInfo['LifeDates'] | undefined => state.UserInfo.userInfo?.LifeDates;

export const accountProfileColumnOrder = (state: RootState): string[] => {
  const accountProfileColumnsSetting = state.UserInfo.userInfo?.ProfileColumnsSetting;
  if (accountProfileColumnsSetting !== '' && accountProfileColumnsSetting) {
    return JSON.parse(accountProfileColumnsSetting)?.profileColumnOrder;
  }
  return null;
};
export const accountProfileDisplayedColumns = (state: RootState): string[] => {
  const accountProfileColumnsSetting = state.UserInfo.userInfo?.ProfileColumnsSetting;
  if (accountProfileColumnsSetting !== '' && accountProfileColumnsSetting) {
    return JSON.parse(accountProfileColumnsSetting)?.profileDisplayedColumns;
  }
  return null;
};
export const getCurrentAccount = (state: RootState): IUserAccount => {
  const accountId = state.UserInfo.userInfo?.AccountDetail.AccountID;
  return (state.UserInfo.accounts || []).find(
    (account) => account.Id === accountId,
  );
};

export const selectCurrentAccountId = (state: RootState) => state.UserInfo.userInfo?.AccountDetail.AccountID;

export const getUserState = (state: RootState): IUserInfoState => state.UserInfo

export const selectIsSyncedCustomer = (state: RootState): boolean => (
  state.UserInfo.userInfo?.AccountDetail?.CaremergeFacilityID > 0
  && hasFeature(
    state.UserInfo.userInfo?.AccountDetail?.EnabledFeatures ?? [],
    'caremerge-profile-sync',
  )
)

export const selectEnabledFeatures = (state: RootState): string[] => (
  state.UserInfo.userInfo?.AccountDetail?.EnabledFeatures ?? []
);

export const getAllAccounts = (state: RootState): IUserAccount[] => state.UserInfo.accounts || [];

export const getAccountIntegrations = (
  state: RootState,
): IUserInfo['AccountIntegrations'] | undefined => state.UserInfo.userInfo?.AccountIntegrations;

export const getCurrentNavItem = (
  state: RootState,
): string | undefined => PersistNavItem.getNavItem();
export const setCurrentNavItem = (
  navItem: string,
): AppThunk => async (dispatch) => PersistNavItem.setNavItem(navItem);
export const getTokenExp = (state: RootState): number => state.UserInfo.jwt.exp

export const getLoggedInStatus = (state: RootState): boolean => {
  const { exp } = state.UserInfo.jwt;
  const utcDate = moment.utc().valueOf();
  if (exp < utcDate / 1000) {
    return false;
  }
  return true;
}
export const selectUserInfo = (state: RootState): IUserInfo => state.UserInfo.userInfo;

export const validateActivationKey = (
  key: string, callback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const response = await Api.UserInfo.activationKeyValidate(key);
    if (!response.Success) {
      dispatch(authenticateUserFailed(response));
      return;
    }
    dispatch(activationKeyValidateSuccess());
    callback();
  } catch (err) {
    if (err?.response?.status === 400) {
      dispatch(activationKeyValidateFailed(err));
    }
    callback();
  }
};
export const userLoginActivate = (
  key: string,
  pwd: string,
  callback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const authResponse = await Api.UserInfo.activateUserLogin(key, pwd);
    handleSaveToken(authResponse.Token);
    log.info('before success');
    dispatch(activationKeyValidateSuccess());
    dispatch(authenticateUserSuccess(authResponse));
    log.info('after success');
    callback();
  } catch (err) {
    dispatch(activateFailed(err));
  }
};
export const validateUserPassword = (
  Password: string, callback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const response = await Api.UserInfo.passwordValidate(Password);
    if (response) {
      dispatch(passwordValidateFailed('Failure'));
      return;
    }
    dispatch(passwordValidateSuccess())
    dispatch(changeUserPassword(Password, () => {
      callback();
    }));
  } catch (err) {
    if (err?.response?.status === 400) {
      dispatch(passwordValidateFailed(err.response.data.Message));
    }
  }
};

export const logout = ({ redirectTo, reason }: { redirectTo?: string, reason?: string } = {}): any => (
  async (dispatch, getState, Api) => {
    await Api.UserInfo.logout();
    handleDestroyToken();

    const query = qs.stringify({
      redirectTo,
      reason,
    });

    window.location.assign(`/login${query ? `?${query}` : ''}`);
  });

export const refreshToken = (newToken: string): AppThunk => (dispatch) => {
  dispatch(refreshTokenSuccess({ newToken }));
  dispatch(fetchUserInfo());
}

/* Utils */

export const handleDestroyToken = (): void => {
  PersistAuth.destroyToken();
  api.unsetAuthHeader();
}

export const handleSaveToken = (token: string): void => {
  PersistAuth.storeToken(token);
  api.setAuthHeader(token);
}

export const updateProfileColumnsSetting = (setting: string): AppThunk => (dispatch) => {
  dispatch(getAccountSettingSuccess(setting));
}

export const linkVoicefriend = (
  jwt: string,
  userName: string,
  pwd: string,
  successCallback: () => void,
  failedCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    await Api.CaremergeIntegration.associateCaremergeUser(jwt, userName, pwd);
    dispatch(linkVoicefriendSuccess())
    successCallback();
  } catch (err) {
    if (err?.response?.status !== 200) {
      dispatch(linkVoicefriendFailed(err.response.data.Message));
    }
    failedCallback();
  }
};

export const authenticateWithCaremergeToken = (
  jwt: string,
  successCallback: (newJwt) => void,
  failedCallback: (error) => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const authResponse = await Api.CaremergeIntegration.exchangeCaremergeToken(jwt);
    handleSaveToken(authResponse.Access_Token);
    dispatch(oauthUserSuccess(authResponse));
    successCallback(authResponse.Access_Token);
  } catch (err) {
    if (err?.response?.status !== 200) {
      dispatch(authenticateUserFailedNoError());
    }
    failedCallback(err);
  }
};

export const fetchCaremergeVFJwt = (
  successCallback: (token) => void,
  failedCallback: () => void,
): AppThunk => async (dispatch, getState, Api) => {
  dispatch(authenticateUserStarted());
  try {
    const res = await Api.CaremergeIntegration.getCaremergeVFJwt();
    dispatch(fetchCaremergeJwtSuccess());
    successCallback(res);
  } catch (err) {
    if (err?.response?.status !== 200) {
      dispatch(fetchCaremergeJwtFailed(err.response.data.Message));
    }
    failedCallback();
  }
};

export const hasFeature = (enabledFeatures, featureSlug): boolean => {
  if (!enabledFeatures) {
    return false;
  }
  return enabledFeatures.includes(featureSlug);
}

export const selectCommunityName = (state: RootState): string => (
  state.UserInfo?.userInfo?.AccountDetail?.CommunityName ?? ''
);

export const generateNewDomainRedirectUrl = (): string => {
  const hostName = window.location.hostname;
  const qaHostName = 'https://voicefriend.icondevelopment.net/';
  const prodHostName = 'https://messaging.goicon.com/';

  const { pathname, search, hash } = document.location;
  const redirectTo = `${pathname}${search}${hash}`;
  const query = qs.stringify({
    redirectTo,
  });
  const redirectParameter = `IconRedirect${query ? `?${query}` : ''}`;

  if (hostName.includes('qa.vf-development')) {
    return `${qaHostName}${redirectParameter}`;
  }

  if (hostName.includes('portal.voicefriend')) {
    return `${prodHostName}${redirectParameter}`;
  }

  return '';
};

export const redirectToIconDomain = (
  token: string,
): boolean => {
  const redirectUrl = generateNewDomainRedirectUrl();
  const refreshtoken = PersistAuth.getRefreshToken();
  if (redirectUrl !== '') {
    // clear token before redirect to new domain
    handleDestroyToken();
    window.location.href = `${redirectUrl}&jwt=${token}&refresh_token=${refreshtoken}`;
    return true;
  }
  return false;
};

export const refreshLegacyToken = (token: string): any => (
  async (dispatch, getState, Api) => {
    await Api.UserInfo.refreshToken(token);
  });

export const selectCustomFieldNames = (state: RootState): string[] => (
  state.UserInfo?.userInfo?.CustomFields.map((field) => field.FieldName) ?? []
);

export const selectIconUsersOnly = (state: RootState): boolean => (
  state.UserInfo?.userInfo?.AccountDetail?.IconUsersOnly
);

export const selectAccountTz = (state: RootState) => state.UserInfo?.accountTimezone;

export const selectIsOotbAccount = (state: RootState) => state.UserInfo.userInfo?.IsOotbAccount;

export const selectInputLanguageDefaultValue = (state: RootState): string => (
  state.UserInfo?.userInfo?.AccountDetail?.InputLanguageDefaultValue ?? 'English'
);
