/* eslint-disable no-param-reassign */
import React, { ReactElement, useRef } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import MergeFields from 'components/MergeFields';

import Textarea from 'components/Inputs/Textarea';
import { ISMSContent } from 'types/ILibraryContent';
import { ContentLength } from './ContentLength';

export function SmsContent({
  smsContent = null,
  isUrlLinkSurvey = false,
}:{
  smsContent?: ISMSContent
  isUrlLinkSurvey?: boolean
}): ReactElement {
  const { control, errors } = useFormContext();
  const textboxRef = useRef<HTMLDivElement|null>(null);

  return (
    <Controller
      control={control}
      name="SMSContent.Content"
      errors={errors?.SMSContent?.Content && 'Content Required'}
      rules={{
        required: 'Content Required',
        validate: (val: any) => {
          if (val === null || val === undefined || val.trim() === '') {
            return 'Content Required';
          }
          if (isUrlLinkSurvey && !val.trim().includes('$$SURVEY_URL$$')) {
            return '$$SURVEY_URL$$ mergefield Required';
          }
          return true;
        },
      }}
      render={({ ref, ...props }) => (
        <>
          <div className="d-flex justify-content-end">
            <ContentLength name="SMSContent.Content" />
            <MergeFields
              dropdownId="sms-content-mergefield"
              type={isUrlLinkSurvey ? 'urlsurevysms' : 'sms'}
              className="mb-2"
              textbox={textboxRef}
              {...props}
            />
          </div>
          <Textarea
            {...props}
            highlights={['<Replace Text Here>']}
            ref={(node: HTMLDivElement) => {
              textboxRef.current = node;
              ref.current = node;
            }}
            maxLength={600}
            rows={5}
            errors={errors?.SMSContent?.Content?.message}
          />
        </>
      )}
    />
  );
}
