import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDownload,
  faClone,
  faSave,
  faFolderOpen,
  faFolderPlus,
  faLongArrowAltRight,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowDown,
  faAt,
  faBars,
  faBriefcaseMedical,
  faCalendarDay,
  faCalendarPlus,
  faCaretDown,
  faCaretRight,
  faCheck,
  faChevronLeft,
  faChevronRight,
  faChevronDown,
  faClipboardList,
  faClock,
  faComment,
  faCommentAlt,
  faEnvelopeSquare,
  faFileExport,
  faFilter,
  faLayerGroup,
  faMobile,
  faPen,
  faPhone,
  faPlus,
  faQuestion,
  faSearch,
  faSlash,
  faSortAlphaDown,
  faSortAlphaUp,
  faSync,
  faThList,
  faTimes,
  faToolbox,
  faTrashAlt,
  faUser,
  faUsers,
  faUsersCog,
  faMinus,
  faExclamationCircle,
  faCheckCircle,
  faRobot,
  faExchangeAlt,
  faPhoneAlt,
  faLink,
  faAward,
  faCalendarCheck,
  faSquare,
  faIndustry,
  faInfo,
  faInfoCircle,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faEllipsisH,
  faEllipsisV,
  faCircle,
  faHospitalUser,
  faSms,
  faExclamationTriangle,
  faRedo,
  faBroadcastTower,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons';

class FontAwesomeLibrary {
  static init(): void {
    library.add(
      faDownload,
      faSave,
      faClone,
      faInfoCircle,
      faFolderOpen,
      faFolderPlus,
      faSquare,
      faLongArrowAltRight,
      faArrowRight,
      faArrowLeft,
      faArrowUp,
      faArrowDown,
      faAt,
      faBars,
      faBriefcaseMedical,
      faCalendarDay,
      faCalendarPlus,
      faCaretDown,
      faCaretRight,
      faCheck,
      faChevronLeft,
      faChevronRight,
      faClipboardList,
      faClock,
      faComment,
      faCommentAlt,
      faEnvelopeSquare,
      faFileExport,
      faFilter,
      faLayerGroup,
      faMobile,
      faPen,
      faPhone,
      faPhone,
      faPlus,
      faQuestion,
      faSearch,
      faSlash,
      faSortAlphaDown,
      faSortAlphaUp,
      faSync,
      faThList,
      faTimes,
      faToolbox,
      faTrashAlt,
      faUser,
      faUsers,
      faUsersCog,
      faMinus,
      faExclamationCircle,
      faCheckCircle,
      faRobot,
      faExchangeAlt,
      faPhoneAlt,
      faLink,
      faAward,
      faCalendarCheck,
      faSquare,
      faIndustry,
      faExpandArrowsAlt,
      faCompressArrowsAlt,
      faChevronDown,
      faInfo,
      faEllipsisH,
      faEllipsisV,
      faCircle,
      faHospitalUser,
      faSms,
      faExclamationTriangle,
      faRedo,
      faBroadcastTower,
      faPaperPlane,
    );
  }
}

export default FontAwesomeLibrary;
