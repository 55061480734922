import { ISurveyCall } from './ISurveyCall';

export enum CommunicationTypeEnum {
  Voice = 'Voice',
  SMS = 'SMS'
}

export interface IDischargedPatient {
  DischargePatientId: number
  ProfileId: string
  ContactProfileId: string
  DischargeDate: string
  NextManualCallDate: string
  NextAutoCallDate: string
  LastCallDate: string
  ProgramStatus: string
  Facility: string
  MRN: string
  DischargeTo: string
  AttendingPhysician: string
  AdmittingDiagnosis: string
  LastResponse: string
  LastCallResult: string
  ReviewRequired: boolean
  LastCallType: string
  NextCallType: string
  LastCallTransactionCount: number
  IncompleteCallCount: number
  CompleteCallCount: number
  TotalCalls: number
  LastCallId: number
  SurveyCalls: ISurveyCall[]
  Notes: string
  AdditionalPhoneNumber: string
  Tags: string[]
  Version: string
  CommunicationType: CommunicationTypeEnum
}

export enum TransitionActionEnum {
  Error = 'Error',
  Enroll = 'Enroll',
  End = 'End'
}

export interface IDischargedPatientConfig {
  inboundnumber: string
  Configuration: {
    ForwardNumber: string
    EmailNotificationData: string[]
    DefaultSurveyQuestions: {
      Auto: number
      Manual: number
    }
    FacilityInfoToCallerIdMapping: any
    StatusConfiguration: {
      EndState: string
      StartState: string
      Forbidden: string[]
      Transitions: Record<string, TransitionActionEnum>
      States: string[]
      MovePatientToEndStateOnLastCall: boolean
    }
    SurveyCallData: IDischargedPatientSurveyCall[]
    Tags: string[]
    ScheduleStrategy: string
    SurveyCallName: string
  }
}

export interface IDischargedPatientConfigGet {
  InboundNumber: string
  ConfigurationData: {
    ForwardNumber: string
    EmailNotificationData: string[]
    DefaultSurveyQuestions: {
      Auto: number
      Manual: number
    }
    FacilityInfoToCallerIdMapping: {
      caller: {
        CallerId: string
        TimeZone:string
      }
    }[]
    StatusConfiguration: {
      EndState: string
      StartState: string
      Forbidden: string[]
      Transitions: Record<string, TransitionActionEnum>
      States: string[]
      MovePatientToEndStateOnLastCall: boolean
    }
    SurveyCallData: IDischargedPatientSurveyCall[]
    Tags: []
    ScheduleStrategy: string
    SurveyCallName: string
  }
}

export interface IDischargedPatientSurveyCall {
  CallType: 'Auto' | 'Manual'
  OffsetDays: number
  TelephonyStatus: 'Incomplete' | 'Complete'
  TaskStatus: 'Incomplete' | 'Complete'
  WellnessCheckId: null // to be dropped
  SurveyQuestionListId: number
  RedialStrategy: RedialStrategy
}

export enum RedialStrategy {
  NoRedials = 'NoRedials',
  Nexion = 'Nexion',
  NextDay = 'NextDay',
  NextDayNoWeekends = 'NextDayNoWeekends',
  FiveMinutes = 'FiveMinutes',
  TwoHourAndNextDay = 'TwoHourAndNextDay'
}

export enum SurveyContentResponseTypeEnum {
  Binary = 'Binary',
  Rating = 'Rating'
}

export interface IDischargedPatientFilterParams {
  searchField?: string
  searchValue?: Record<string, any>|string
  sortField?: string
  sortDirection?: string
}
