import React, {
  FC, useMemo, useEffect,
} from 'react';
import {
  useForm, SubmitHandler, Controller, FormProvider,
} from 'react-hook-form';
import { Row, Col, Button } from 'react-bootstrap';
import ReactSelect from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Input, Textarea, Select } from 'components/FormControls';
import { IFormInputs, ITask } from 'pages/Administration/DischargedPatient/types';
import {
  selectDischargedPatientTags,
  selectDischargedPatientConfig,
  updateDischargedPatientConfig,
  getDischargedPatientConfig,
} from 'reducers/DischargedPatients';
import { selectAll } from 'reducers/SurveyQuestionList';

import CallerIdMapping from '../caller-id-mapping/CallerIdMapping';

export const InfoForm: FC<{ readMode: boolean, onCancel: () => void }> = ({ onCancel, readMode }) => {
  const formMethods = useForm<IFormInputs>();
  const {
    handleSubmit, control, errors, reset,
  } = formMethods;
  const tags = useSelector(selectDischargedPatientTags);
  const allSurveyQuestionLists = useSelector(selectAll);
  const config = useSelector(selectDischargedPatientConfig);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!config) {
      return;
    }

    const defaultValues = {
      ForwardNumber: config.ConfigurationData?.ForwardNumber ?? '',
      inboundnumber: config.InboundNumber ?? '',
      EmailNotificationData: config.ConfigurationData?.EmailNotificationData?.join() || '',
      Manual: config.ConfigurationData?.DefaultSurveyQuestions?.Manual ?? 0,
      Auto: config.ConfigurationData?.DefaultSurveyQuestions?.Auto ?? 0,
      Tags: config.ConfigurationData?.Tags.map((tag) => ({ label: tag, value: tag })) || [],
      FacilityInfoToCallerIdMapping: Object.keys(config.ConfigurationData?.FacilityInfoToCallerIdMapping)
        .map((key) => ({
          name: key,
          ...config.ConfigurationData.FacilityInfoToCallerIdMapping[key],
        })),
    }

    reset(defaultValues);
  }, [config, reset, readMode]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const EmailNotificationData = data.EmailNotificationData.split(/\s*,\s*/).filter(Boolean);

    const FacilityInfoToCallerIdMapping = data.FacilityInfoToCallerIdMapping.reduce((obj, item) => ({
      ...obj,
      [item.name.toString()]: {
        CallerId: item.CallerId,
        TimeZone: item.TimeZone,
      },
    }), {});

    const Tags: string[] = data?.Tags?.map((x: ITask) => x.value) ?? []

    const objectToEndpoint = {
      inboundnumber: data.inboundnumber,
      Configuration: {
        EmailNotificationData,
        ForwardNumber: data.ForwardNumber,
        DefaultSurveyQuestions: {
          Auto: data.Auto,
          Manual: data.Manual,
        },
        FacilityInfoToCallerIdMapping,
        Tags,
        ScheduleStrategy: config.ConfigurationData?.ScheduleStrategy,
        StatusConfiguration: config.ConfigurationData?.StatusConfiguration,
        SurveyCallData: config.ConfigurationData?.SurveyCallData,
        SurveyCallName: config.ConfigurationData?.SurveyCallName,
      },
    }

    dispatch(updateDischargedPatientConfig(objectToEndpoint, () => {
      toast.success('Discharged Patient Configuration successfully saved');
      getDischargedPatientConfig();
      onCancel();
    }));
  }

  const tagsOptions = useMemo(
    () => tags.map((tag) => ({ label: tag, value: tag })),
    [tags],
  );

  const manualAutoOptions = useMemo(() => allSurveyQuestionLists.map((item) => ({
    label: item.Name,
    value: `${item.SurveyQuestionListId}`,
  })), [allSurveyQuestionLists]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-100 mt-3">
        <Row>
          <Col xs={12} md={6}>
            <Input
              id="inboundnumber"
              name="inboundnumber"
              label="Inbound Phone number"
              type="text"
              control={control}
              errors={errors.inboundnumber && 'Inbound Phone number is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
            <Input
              id="ForwardNumber"
              name="ForwardNumber"
              label="Forward Phone number"
              type="text"
              control={control}
              errors={errors.ForwardNumber && 'Forward Phone number is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
            <Textarea
              id="EmailNotificationData"
              control={control}
              name="EmailNotificationData"
              label="Email"
              errors={errors.EmailNotificationData && 'Email is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
          </Col>
          <Col xs={12} md={6}>
            <Select
              id="Manual"
              name="Manual"
              label="New Manual Call"
              placeholder="Select survey question list"
              control={control}
              options={manualAutoOptions}
              errors={errors.Manual && 'Manual Call is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
            <Select
              id="Auto"
              name="Auto"
              label="New Auto Call"
              placeholder="Select survey question list"
              control={control}
              options={manualAutoOptions}
              errors={errors.Auto && 'Auto Call is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
            <CallerIdMapping readMode={readMode} />
            <Controller
              isMulti
              as={ReactSelect}
              name="Tags"
              placeholder="Select tags"
              options={tagsOptions}
              control={control}
              className="form-group"
              isDisabled={readMode}
            />
          </Col>
        </Row>
        {!readMode && (
          <div className=" w-100 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link mr-3"
              onClick={onCancel}
            >
              Cancel
            </button>
            <Button
              type="submit"
            >
              Save
            </Button>
          </div>
        )}

      </form>
    </FormProvider>
  )
}
