import { Switch, Route } from 'react-router-dom';
import React from 'react';
import ReservedDomainsPage from 'pages/Internal/ReservedDomains';
import UpdateBroadcastFormGenerator from 'pages/MultiAccountSurveys/UpdateEbfGeneratorPage';
import AccountSettingsPage from '../pages/Internal/AccountSettings';
import MultiAccountSurveyConfigPage from '../pages/MultiAccountSurveys/Index';
import CreateBroadcastFormGenerator from '../pages/MultiAccountSurveys/NewEbfGeneratorPage';
import EbfGeneratorEventsPage from '../pages/MultiAccountSurveys/EbfGeneratorEventsPage';

function InternalSettingRoutes() {
  return (
    <Switch>
      <Route path="/internal/accountsettings" component={AccountSettingsPage} />
      <Route path="/internal/reserveddomains" component={ReservedDomainsPage} />
      <Route path="/internal/multiaccountsurvey" component={MultiAccountSurveyConfigPage} />
      <Route path="/internal/ebfgenerators/new" component={CreateBroadcastFormGenerator} />
      <Route path="/internal/ebfgenerators/:generatorId/events" component={EbfGeneratorEventsPage} />
      <Route path="/internal/ebfgenerators/:generatorId" component={UpdateBroadcastFormGenerator} />
    </Switch>
  );
}

export default InternalSettingRoutes;
