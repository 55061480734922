import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PbiEmbeddedReport from 'pages/Reports/PbiEmbeddedReport';
import CreatePbiSession from 'components/PowerBi/CreatePbiSession';

function PbiRoutes() {
  return (
    <Switch>
      <Route path="/pbi/reports/:datasetId/:reportId/:pbiSessionId" component={PbiEmbeddedReport} />
      <Route path="/pbi/sessions/create" component={CreatePbiSession} />
    </Switch>
  );
}

export default PbiRoutes;
