import React, {
  FC, useState, useMemo, useEffect,
} from 'react'

import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import _times from 'lodash/times';
import { Row, Col, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ReactSortable } from 'react-sortablejs';

import { IDischargedPatientSurveyCall, RedialStrategy } from 'types/IDischargedPatient';
import {
  selectDischargedPatientConfig,
  updateDischargedPatientConfig,
  getDischargedPatientConfig,
} from 'reducers/DischargedPatients';
import { selectAll } from 'reducers/SurveyQuestionList';
import { Select, Input } from 'components/FormControls';
import { IFormInputs } from 'pages/Administration/DischargedPatient/types';

export const ScheduleForm: FC<{ readMode: boolean, onCancel: () => void }> = (
  { readMode, onCancel },
) => {
  const config = useSelector(selectDischargedPatientConfig);
  const allSurveyQuestionLists = useSelector(selectAll);
  const [rows, setRows] = useState<number[]>([0]);
  const dispatch = useDispatch();

  const formMethods = useForm<IFormInputs>();
  const {
    handleSubmit, control, errors, reset, getValues,
  } = formMethods;

  useEffect(() => {
    if (!config) {
      return;
    }
    setRows(_times((config.ConfigurationData?.SurveyCallData?.length ?? 1)));
  }, [config, readMode]);

  useEffect(() => {
    if (!config) {
      return;
    }

    const defaultValues = {
      SurveyCallName: config.ConfigurationData?.SurveyCallName?.replace(`Schedule ${1} - `, '')
      ?? '',
      SurveyCallData: config.ConfigurationData?.SurveyCallData?.map((item) => ({
        CallType: item.CallType ?? '',
        OffsetDays: item.OffsetDays?.toString() ?? '',
        RedialStrategy: item.RedialStrategy ?? '',
        SurveyQuestionListId: `${item.SurveyQuestionListId ?? ''}`,
      })) ?? [],
    }

    reset(defaultValues);
  }, [config, reset, readMode]);

  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    const SurveyCallData = rows
      .filter((key) => data.SurveyCallData[key].CallType !== '')
      .map((key) => ({
        ...data.SurveyCallData[key],
        SurveyQuestionListId: +data.SurveyCallData[key].SurveyQuestionListId,
        OffsetDays: +data.SurveyCallData[key].OffsetDays,
        TelephonyStatus: 'Incomplete',
        TaskStatus: 'Incomplete',
      } as IDischargedPatientSurveyCall));

    const objectToEndpoint = {
      inboundnumber: config.InboundNumber,
      Configuration: {
        EmailNotificationData: config.ConfigurationData?.EmailNotificationData,
        ForwardNumber: config.ConfigurationData?.ForwardNumber,
        DefaultSurveyQuestions: config.ConfigurationData?.DefaultSurveyQuestions,
        FacilityInfoToCallerIdMapping: config.ConfigurationData?.FacilityInfoToCallerIdMapping,
        Tags: config.ConfigurationData?.Tags,
        ScheduleStrategy: config.ConfigurationData?.ScheduleStrategy,
        StatusConfiguration: config.ConfigurationData?.StatusConfiguration,
        SurveyCallName: data.SurveyCallName,
        SurveyCallData,
      },
    }

    dispatch(updateDischargedPatientConfig(objectToEndpoint, () => {
      toast.success('Discharged Patient Configuration successfully saved');
      dispatch(getDischargedPatientConfig());
      onCancel();
    }));
  }

  const typeOptions = useMemo(() => [
    '',
    'Manual',
    'Auto',
  ].map((item) => ({
    label: item,
    value: item,
  })), []);

  const redialStrategyOptions = useMemo(() => [
    { label: '', value: '' },
    { label: 'No Redials', value: RedialStrategy.NoRedials },
    { label: 'Nexion', value: RedialStrategy.Nexion },
    { label: 'Next Day', value: RedialStrategy.NextDay },
    { label: 'Next Day No Weekends', value: RedialStrategy.NextDayNoWeekends },
    { label: 'Five Minutes', value: RedialStrategy.FiveMinutes },
    { label: 'Two Hour And Next Day', value: RedialStrategy.TwoHourAndNextDay },
  ], []);

  const surveyQuestionListOptions = useMemo(() => [
    { label: '', value: '' },
    ...allSurveyQuestionLists.map((item) => ({
      label: item.Name,
      value: `${item.SurveyQuestionListId}`,
    })),
  ], [allSurveyQuestionLists]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} className="h-100 mt-3">
        <Row>
          <Col xs={12} sm={6} lg={5} xl={3}>
            <Input
              id="SurveyCallName"
              name="SurveyCallName"
              label="Schedule Name"
              type="text"
              control={control}
              errors={errors.SurveyCallName && 'Schedule Name is required'}
              rules={{ required: true }}
              readOnly={readMode}
            />
          </Col>
          <Col xs={12}>
            <h6 className="h6">Calls</h6>
            <div style={{ paddingTop: '0.2rem', marginTop: '0.2rem' }}>
              <ReactSortable
                list={rows.map((key) => ({ id: key }))}
                setList={(updatedList) => {
                  setRows(updatedList.map((item) => item.id))
                }}
                animation={150}
                handle=".draggable-handle"
                disabled={readMode}
                draggable=".draggable-item"
                tag="div"
              >
                {rows.map((key, index) => (
                  <Row className="align-items-center draggable-item flex-nowrap mx-0" key={key}>
                    <Col className="flex-grow-0 p-0" style={{ flexBasis: '45px', minWidth: '45px', marginTop: '12px' }}>
                      <div>
                        <FontAwesomeIcon
                          icon={faArrowsAlt}
                          className="draggable-handle"
                          style={{ marginRight: '0.5rem', cursor: 'move' }}
                        />
                        {index + 1}
                      </div>
                    </Col>
                    <Col style={{ minWidth: 'fit-content' }}>
                      <Row className="flex-nowrap">
                        <Col className="pr-0">
                          <Select
                            id={`SurveyCallData.${key}.CallType`}
                            name={`SurveyCallData.${key}.CallType`}
                            options={typeOptions}
                            label="Call Type"
                            control={control}
                            style={{ minWidth: '12rem' }}
                            defaultValue=""
                            rules={{
                              validate: (val: string) => {
                                const values = getValues();
                                if (!val && (
                                  values.SurveyCallData?.[key]?.OffsetDays
                                || values.SurveyCallData?.[key]?.SurveyQuestionListId
                                || values.SurveyCallData?.[key]?.RedialStrategy
                                )) {
                                  return 'Call Type is required';
                                }

                                if (!val && values.SurveyCallData
                                  .filter((item) => (
                                    item.OffsetDays && item.SurveyQuestionListId && item.RedialStrategy && item.CallType
                                  ))
                                  .length < 1
                                ) {
                                  return 'Call Type is required';
                                }

                                return true;
                              },
                            }}
                            errors={errors.SurveyCallData?.[key]?.CallType?.message}
                            readOnly={readMode}
                          />
                        </Col>
                        <Col className="pr-0">
                          <Input
                            id={`SurveyCallData.${key}.OffsetDays`}
                            name={`SurveyCallData.${key}.OffsetDays`}
                            label="Offset Days"
                            type="text"
                            control={control}
                            style={{ minWidth: '12rem' }}
                            errors={errors.SurveyCallData?.[key]?.OffsetDays?.message}
                            rules={{
                              validate: (val: string) => {
                                const values = getValues();
                                if (!val && (
                                  values.SurveyCallData?.[key]?.CallType
                                || values.SurveyCallData?.[key]?.SurveyQuestionListId
                                || values.SurveyCallData?.[key]?.RedialStrategy
                                )) {
                                  return 'Offset Days is required';
                                }

                                if (!val && values.SurveyCallData
                                  .filter((item) => (
                                    item.OffsetDays && item.SurveyQuestionListId && item.RedialStrategy && item.CallType
                                  ))
                                  .length < 1
                                ) {
                                  return 'Offset Days is required';
                                }

                                return true;
                              },
                            }}
                            readOnly={readMode}
                          />
                        </Col>
                        <Col className="pr-0">
                          <Select
                            id={`SurveyCallData.${key}.SurveyQuestionListId`}
                            name={`SurveyCallData.${key}.SurveyQuestionListId`}
                            options={surveyQuestionListOptions}
                            label="Survey Question List"
                            control={control}
                            style={{ minWidth: '12rem' }}
                            defaultValue=""
                            rules={{
                              validate: (val: string) => {
                                const values = getValues();
                                if (!val && (
                                  values.SurveyCallData?.[key]?.CallType
                                || values.SurveyCallData?.[key]?.OffsetDays
                                || values.SurveyCallData?.[key]?.RedialStrategy
                                )) {
                                  return 'Survey Question List is required';
                                }

                                if (!val && values.SurveyCallData
                                  .filter((item) => (
                                    item.OffsetDays && item.SurveyQuestionListId && item.RedialStrategy && item.CallType
                                  ))
                                  .length < 1
                                ) {
                                  return 'Survey Question List is required';
                                }

                                return true;
                              },
                            }}
                            errors={errors.SurveyCallData?.[key]?.SurveyQuestionListId?.message}
                            readOnly={readMode}
                          />
                        </Col>
                        <Col className="pr-0">
                          <Select
                            id={`SurveyCallData.${key}.RedialStrategy`}
                            name={`SurveyCallData.${key}.RedialStrategy`}
                            options={redialStrategyOptions}
                            label="Redial Strategy"
                            control={control}
                            style={{ minWidth: '12rem' }}
                            defaultValue=""
                            rules={{
                              validate: (val: string) => {
                                const values = getValues();
                                if (!val && (
                                  values.SurveyCallData?.[key]?.CallType
                                || values.SurveyCallData?.[key]?.SurveyQuestionListId
                                || values.SurveyCallData?.[key]?.OffsetDays
                                )) {
                                  return 'Redial Strategy is required';
                                }

                                if (!val && values.SurveyCallData
                                  .filter((item) => (
                                    item.OffsetDays && item.SurveyQuestionListId && item.RedialStrategy && item.CallType
                                  ))
                                  .length < 1
                                ) {
                                  return 'Redial Strategy is required';
                                }

                                return true;
                              },
                            }}
                            errors={errors.SurveyCallData?.[key]?.RedialStrategy?.message}
                            readOnly={readMode}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Col
                      className="flex-grow-0 pr-0"
                      style={{
                        flexBasis: '30.75px', minWidth: '30.75px', marginTop: '12px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon="trash-alt"
                        style={{
                          cursor: (rows.length === 1 || readMode) ? 'default' : 'pointer',
                          opacity: (rows.length === 1 || readMode) ? 0.5 : 1,
                          fontSize: '18px',
                        }}
                        color={(rows.length === 1 || readMode) ? 'gray' : '#FF7052'}
                        onClick={() => {
                          if (rows.length === 1 || readMode) {
                            return;
                          }
                          setRows([
                            ...rows.slice(0, index),
                            ...rows.slice(index + 1),
                          ]);
                        }}
                      />
                    </Col>
                  </Row>
                ))}
              </ReactSortable>
            </div>
            <div className="d-flex justify-content-end">
              <FontAwesomeIcon
                icon={faPlusSquare}
                className="mb-3"
                color="#0F4CDB"
                size="2x"
                style={{
                  cursor: readMode ? 'default' : 'pointer', fontSize: 24,
                }}
                onClick={() => {
                  if (readMode) {
                    return;
                  }
                  setRows([...rows, Math.max(...rows) + 1]);
                }}
              />
            </div>

          </Col>
        </Row>
        {!readMode && (
          <div className=" w-100 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-link mr-3"
              onClick={onCancel}
            >
              Cancel
            </button>
            <Button
              type="submit"
            >
              Save
            </Button>
          </div>
        )}

      </form>
    </FormProvider>
  )
}

export default ScheduleForm;
