import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { Redirect, useHistory } from 'react-router-dom';
import { ReactComponent as Plus } from 'styles/images/plus.svg';
import { useHasRoles } from 'hooks/useHasRoles';
import { useHasPermissions } from 'hooks/useHasPermissions';
import { useHasFeatures } from 'hooks/useHasFeatures';
import { Spinner } from 'react-bootstrap';
import { BroadcastFormTable } from './BroadcastFormTable';

const UrlLinkSurveyEventsIndexPage = ():ReactElement => {
  const history = useHistory();
  const [isAdmin, isSuper] = useHasRoles(['Admin', 'Super']);
  const hasAccessPermission = useHasPermissions('Calendar:Read') || isAdmin || isSuper;
  const { hasFeature, isLoading } = useHasFeatures('broadcast-forms');
  if (!hasAccessPermission) {
    return <Redirect to="/" />
  }
  if (isLoading) {
    return (
      <Spinner
        animation="border"
        variant="primary"
        role="status"
        className="mx-auto d-block mt-4 flex-shrink-0"
      />
    );
  } if (!hasFeature) {
    return <div>Broadcast Forms is not enabled for this account</div>
  }

  return (
    <div className="broadcast-forms Templates">
      <Helmet>
        <title>URL Link Survey Events</title>
      </Helmet>
      <div className="header-bar broadcast-forms__header-bar">
        <button
          role="link"
          type="button"
          onClick={() => history.push('/urllinksurvey/new')}
          className="btn btn-lg btn-warning "
        >
          <Plus
            width="24"
            height="24"
            fill="currentColor"
            style={{ marginRight: '10px' }}
          />
          Add New Survey
        </button>
        <div className="broadcast-forms__header-description">
          Send a broadcast message containing a unique survey link for each recipient to complete. Resend surveys to recipients who&apos;ve yet to respond and view the aggregated results.
        </div>
      </div>
      <BroadcastFormTable
        showActions
        filterTitle="Form"
      />
    </div>
  );
};
export default UrlLinkSurveyEventsIndexPage;
