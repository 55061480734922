import { IDischargedPatient } from 'types/IDischargedPatient';
import { ISurveyCall } from 'types/ISurveyCall';
import moment from 'moment';

export const buildDefaultSurveyCall = (
  dischargedPatient: IDischargedPatient | null | undefined,
  timezone: string,
): ISurveyCall => ({
  SurveyCallId: -1,
  DischargePatientId: dischargedPatient?.DischargePatientId,
  SendDateTimeUtc: moment.utc().format('yyyy-MM-DD HH:mm'),
  SendDate: moment.tz(timezone).format('yyyy-MM-DD'),
  SendTime: moment.tz(timezone).format('HH:mm'),
  CallType: 'Auto',
  TelephonyStatus: 'Incomplete',
  Comments: '',
  WellnessCheckId: '',
  SurveyQuestionListId: 0,
  TaskStatus: 'Incomplete',
  TransactionCount: 0,
  SurveyCallContent: [],
  TransferTransactions: null,
  RedialStrategy: 'NoRedials',
  Version: undefined,
});
